import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RouterModule } from '@angular/router';
import { HeaderComponent } from '../header/header.component';
import { FooterComponent } from '../footer/footer.component';
import { BreadcrumbsComponent } from '../breadcrumbs/breadcrumbs.component';
import { SidebarComponent } from '../sidebar/sidebar.component';
import { LoadingComponent } from '../loading/loading.component';


@NgModule({
  declarations: [HeaderComponent, FooterComponent, SidebarComponent, LoadingComponent],
  imports: [
    CommonModule,
    RouterModule
  ],
  exports: [HeaderComponent, FooterComponent, SidebarComponent, LoadingComponent],
})
export class SharedModule { }
